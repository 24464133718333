import request from '@/util/request'


// 获得支付数据
// let params = {
//     id: this.id,
//     payType: this.payType == 3 ? 2 : 1, //支付方式 1线上 2线下
//     payProof: this.payProof,
// };
export function getPayOrder(data) {
    return request({
        url: '/api/member/memberApply/getOrderInfo',
        method: 'POST',
        data
    })
}

//获得二维码
// {orderNumber}
export function getQrcode(params) {
    return request({
        url: '/api/pay/pay',
        method: 'GET',
        params
    })
}

//支付
export function payNative(data) {
    return request({
        url: '/api/pay/wx/native',
        method: 'post',
        data
    })
}
